<template>
    <v-app id="inspire">
        <v-navigation-drawer v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" app>
            <v-list>
                <v-list-item v-for="(item, i) in items" :key="i" :to="item.to" router exact>
                    <v-list-item-action>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title v-text="item.text" />
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app color="blue darken-3" dark>
            <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
                <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
                <nuxt-link :to="defaultRoute"><span class="hidden-sm-and-down" v-text="title"></span></nuxt-link>
            </v-toolbar-title>
            <div class="flex-grow-1"></div>
            <v-spacer />
            <v-menu v-if="$auth.loggedIn" bottom left>
                <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on">
                        <v-icon large>mdi-account-circle</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item @click="logout">
                        <v-list-item-title>Logout</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>
        <v-content>
            <v-container>
                <!--class="fill-height" fluid-->
                <nuxt />
            </v-container>
        </v-content>
    </v-app>
</template>

<script>
export default {
    data: () => ({
        title: 'Educor Course Engine',
        dialog: false,
        drawer: null,
        defaultRoute: '/',
        items: [
            { icon: 'mdi-cloud-upload', text: 'Import', to: '/import' },
            { icon: 'mdi-school', text: 'Faculties', to: '/faculties' },
            { icon: 'mdi-office-building', text: 'Brands', to: '/brands' },
        ],
    }),
    methods: {
        logout() {
            this.$auth.logout()
        },
    },
}
</script>
<style scoped lang="scss">
.v-toolbar__title {
    a {
        color: inherit;
        text-decoration: inherit;
    }
}
</style>
