import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _67221455 = () => interopDefault(import('../src/pages/brands/index.vue' /* webpackChunkName: "pages/brands/index" */))
const _2a1acd22 = () => interopDefault(import('../src/pages/faculties/index.vue' /* webpackChunkName: "pages/faculties/index" */))
const _0818b224 = () => interopDefault(import('../src/pages/import/index.vue' /* webpackChunkName: "pages/import/index" */))
const _e831a25a = () => interopDefault(import('../src/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _f939b006 = () => interopDefault(import('../src/pages/brands/_id.vue' /* webpackChunkName: "pages/brands/_id" */))
const _1be62b97 = () => interopDefault(import('../src/pages/faculties/_id.vue' /* webpackChunkName: "pages/faculties/_id" */))
const _7b80e888 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/brands",
    component: _67221455,
    name: "brands"
  }, {
    path: "/faculties",
    component: _2a1acd22,
    name: "faculties"
  }, {
    path: "/import",
    component: _0818b224,
    name: "import"
  }, {
    path: "/login",
    component: _e831a25a,
    name: "login"
  }, {
    path: "/brands/:id",
    component: _f939b006,
    name: "brands-id"
  }, {
    path: "/faculties/:id",
    component: _1be62b97,
    name: "faculties-id"
  }, {
    path: "/",
    component: _7b80e888,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
